<aca-search-ai-input
  (searchSubmitted)="hideSearchInput()"
  [placeholder]="placeholder"
  [agentId]="agentId"
  [useStoredNodes]="useStoredNodes">
</aca-search-ai-input>
<mat-divider
  [vertical]="true"
  class="aca-search-ai-input-container-divider">
</mat-divider>
<button
  mat-icon-button
  (click)="leaveSearchInput()"
  data-automation-id="aca-search-ai-input-container-leaving-search-button"
  [title]="'KNOWLEDGE_RETRIEVAL.SEARCH.SEARCH_INPUT.HIDE_INPUT' | translate"
  class="aca-search-ai-input-container-close">
  <mat-icon>close</mat-icon>
</button>
