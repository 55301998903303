<div class="aca-search-ai-input-agent-container">
  <mat-select
    [formControl]="agentControl"
    class="aca-search-ai-input-agent-select"
    panelClass="aca-search-ai-input-agent-select-options aca-search-ai-input-agent-select-agents"
    data-automation-id="aca-search-ai-agents-select"
  [hideSingleSelectionIndicator]="true">
    <mat-select-trigger class="aca-search-ai-input-agent-select-displayed-value">
      <adf-avatar
        [src]="agentControl.value?.avatarUrl"
        [initials]="initialsByAgentId[agentControl.value?.id]"
        size="26px">
      </adf-avatar>
      <span class="aca-search-ai-input-agent-select-displayed-value-text">{{ agentControl.value?.name }}</span>
    </mat-select-trigger>
    <mat-option
      *ngFor="let agent of agents"
      [value]="agent"
      class="aca-search-ai-input-agent-select-options-option"
      [attr.data-automation-id]="'aca-search-ai-input-agent-' + agent.id"><div class="aca-search-ai-input-agent-select-options-option-content">
        <adf-avatar [src]="agent?.avatarUrl" [initials]="initialsByAgentId[agent.id]"></adf-avatar>
        <span class="aca-search-ai-input-agent-select-options-option-content-text" [matTooltip]="agent.name" [matTooltipPosition]="'right'">{{ agent.name }}</span>
      </div>
    </mat-option>
  </mat-select>

  <div class="aca-search-ai-input-agent-popup-hover-card">
    <mat-card class="aca-search-ai-input-agent-popup-hover-card-container">
      <mat-card-title class="aca-search-ai-input-agent-popup-hover-card-container-title">
        <adf-avatar
          [initials]="initialsByAgentId[agentControl.value?.id]"
          [src]="agentControl.value?.avatarUrl"
          size="50px">
        </adf-avatar>
        <span class="aca-search-ai-input-agent-popup-hover-card-container-title-name"
              [matTooltipPosition]="'right'"
              [matTooltip]="agentControl.value?.name">
            {{ agentControl.value?.name }}
        </span>
      </mat-card-title>
      <mat-card-content class="aca-search-ai-input-agent-popup-hover-card-container-content">
        {{ agentControl.value?.description }}
      </mat-card-content>
    </mat-card>
  </div>
</div>
<input
  class="aca-search-ai-input-text"
  matInput
  [formControl]="queryControl"
  data-automation-id="aca-search-ai-input"
  [placeholder]="placeholder | translate"
  (keyup.enter)="onSearchSubmit()"/>
<button
  mat-flat-button
  color="primary"
  class="aca-search-ai-asking-button"
  (click)="onSearchSubmit()"
  [disabled]="!queryControl.value"
  data-automation-id="aca-search-ai-asking-button">
  <adf-icon [value]="'adf:three_magic_stars_ai'"></adf-icon>
  <span class="aca-search-ai-asking-button-label">{{ 'KNOWLEDGE_RETRIEVAL.SEARCH.SEARCH_INPUT.ASK_BUTTON_LABEL' | translate }}</span>
</button>
